/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/**
 * @author Rishabh
 * @classdesc This class is defining an implementation from Base
 * @version 1.0
 */

import { SubscriptionBlock, TagManager } from "@accor/ace-ui-core";

export default class IbisSubscriptionBlock extends SubscriptionBlock {
  /** @inheritDoc */

  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class (CoreJS.BaseComponent)
    super(componentHost);
  }

  tagManagerPush() {
    const dataLayer = document
      ?.querySelector("[data-cmp-datalayer]")
      ?.getAttribute("data-cmp-datalayer");
    if (dataLayer) {
      const datalayerValue = JSON.parse(dataLayer);
      const dataEvent = {
        event: "GA4event",
        eventName: "bloc_interact",
        event_data: {
          pagename: datalayerValue?.pageName,
          bloc_name: "all member offer",
          bloc_interaction: this.loyaltyButtonSpan?.innerText
            ?.trim()
            ?.toLowerCase(),
        },
      };
      TagManager.trackEvent(dataEvent);
    }
  }
}

// Register the SubscriptionBlock component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(
  SubscriptionBlock.CLASS_NAMESPACE,
  IbisSubscriptionBlock,
);
