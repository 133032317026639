/* eslint-disable no-unused-expressions */
/**
 * Class representing a Ibis Loyalty Component instance
 * @author Rishabh
 * @class IbisLoyalty
 * @classdesc Ibis Loyalty component to inherit the Loyalty core component
 * @extends Loyalty
 * @version 1.0
 */
import { Loyalty, TagManager } from "@accor/ace-ui-core";

export default class IbisLoyalty extends Loyalty {
  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class
    super(componentHost);
    const dataLayerValue = document
      .querySelector("[data-cmp-datalayer]")
      ?.getAttribute("data-cmp-datalayer");
    if (dataLayerValue) {
      this.dataLayerJson = JSON.parse(dataLayerValue);
    }
    this.loyaltyCta = this.componentHost.querySelectorAll(
      ".ace-loyalty__button a",
    );
    this.TagManagerHandler();
  }

  /**
   * Push data in tagmanager
   */
  TagManagerHandler() {
    if (this.dataLayerJson) {
      this.loyaltyCta?.forEach((element) => {
        element?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          this.dataEvent = {
            event: "GA4event",
            eventName: "bloc_interact",
            event_data: {
              pagename: this.dataLayerJson?.pageName,
              bloc_name: "all member offer",
              bloc_interaction: element?.innerText?.trim()?.toLowerCase(),
            },
          };
          TagManager.trackEvent(this.dataEvent);
        });
      });
    }
  }
}

// Register the Sofitel Social Media component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(
  Loyalty.CLASS_NAMESPACE,
  IbisLoyalty,
  true,
);
