/* eslint-disable no-unused-expressions */
/**
 * Class representing a Ace Callout Container Component instance
 * @author Rishabh
 * @class IbisCalloutContainer
 * @classdesc Ibis callout container component to inherit the callout container core component
 * @extends CalloutContainer
 * @version 1.0
 */
import { CalloutContainer, TagManager } from "@accor/ace-ui-core";

export default class IbisCalloutContainer extends CalloutContainer {
  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class
    super(componentHost);
    const dataLayerValue = document
      .querySelector("[data-cmp-datalayer]")
      ?.getAttribute("data-cmp-datalayer");
    if (dataLayerValue) {
      this.dataLayerJson = JSON.parse(dataLayerValue);
    }

    this.dataEvent = {
      event: "GA4event",
      eventName: "bloc_interact",
      event_data: {
        pagename: this.dataLayerJson?.pageName,
      },
    };

    this.calloutContainerItem = this.componentHost.querySelectorAll(
      ".callout-section ul li",
    );
    this.calloutContainerCTA = this.componentHost.querySelector(
      ".ace-loyalty__inner",
    );
    this.pageHeadingTitle = document
      .querySelector(
        '.heading .ace-headingpagehero-headline [class*="expressive-heading"]',
      )
      ?.innerText?.trim()
      ?.toLowerCase();

    this.calloutTagManagerHandle();
    this.calloutCtaTagManagerHandle();
  }

  /**
   * push ga events for callouts in tag manager depending on the page
   */
  calloutTagManagerHandle() {
    if (this.dataLayerJson) {
      this.calloutContainerItem?.forEach((element, index) => {
        element
          ?.querySelector(".ace-callout-component")
          ?.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
            const calloutTitle = element
              ?.querySelector(".cmp-teaser__pretitle")
              ?.innerText?.trim()
              ?.toLowerCase();
            if (this.dataLayerJson?.pageName === "ibis::brand::homepage") {
              // Homepage Template
              if (
                (!element?.closest(".experience-fragment") ||
                  !element?.closest(".target")) &&
                !element?.closest(".section-background-container")
              ) {
                // For normal callouts
                this.dataEvent.event_data.bloc_name = "ibis brands";
                this.dataEvent.event_data.bloc_interaction = calloutTitle;
                this.dataEvent.event_data.bloc_type = `go to ${calloutTitle}`;
              } else if (element?.closest(".section-background-container")) {
                // For section callout
                this.dataEvent.event_data.bloc_name = "what s on";
                this.dataEvent.event_data.bloc_interaction = calloutTitle;
              } else if (
                element?.closest(".experience-fragment") ||
                element?.closest(".target")
              ) {
                // For experience fragement
                if (
                  element
                    ?.closest(".experience-fragment")
                    ?.previousElementSibling?.previousElementSibling?.classList?.contains(
                      "booking-engine-container",
                    ) ||
                  element
                    ?.closest(".target")
                    ?.previousElementSibling?.previousElementSibling?.classList?.contains(
                      "booking-engine-container",
                    )
                ) {
                  this.dataEvent.event_data.bloc_name = "ibis offers";
                  this.dataEvent.event_data.bloc_interaction = calloutTitle;
                } else {
                  this.dataEvent.event_data.bloc_name = "destinations";
                  this.dataEvent.event_data.bloc_interaction = calloutTitle;
                }
              }
            } else if (
              this.dataLayerJson?.pageName?.split("::")[1] === "brands"
            ) {
              // Brand page template
              if (
                (!element?.closest(".experience-fragment") ||
                  !element?.closest(".target")) &&
                !element?.closest(".section-background-container")
              ) {
                // For normal callouts
                if (
                  this.componentHost
                    ?.closest(".callout-container")
                    ?.previousElementSibling?.previousElementSibling?.classList?.contains(
                      "booking-engine-container",
                    )
                ) {
                  this.dataEvent.event_data.bloc_name = `news ${this.dataLayerJson?.pageName?.split("::")[2]}`;
                  this.dataEvent.event_data.bloc_interaction = calloutTitle;
                } else {
                  this.dataEvent.event_data.bloc_name = "other brands";
                  this.dataEvent.event_data.bloc_interaction = `go to ${calloutTitle}`;
                }
              } else if (element?.closest(".section-background-container")) {
                // For section callout
                this.dataEvent.event_data.bloc_name = `${this.dataLayerJson?.pageName?.split("::")[2]} offers`;
                this.dataEvent.event_data.bloc_interaction = calloutTitle;
              } else if (
                element?.closest(".experience-fragment") ||
                element?.closest(".target")
              ) {
                // For experience fragement
                this.dataEvent.event_data.bloc_name = `${this.dataLayerJson?.pageName?.split("::")[2]} destinations`;
                this.dataEvent.event_data.bloc_interaction = calloutTitle;
              }
            } else if (this.dataLayerJson?.pageName === "ibis::magazine::hub") {
              // Magazine Hub template
              if (
                (!element?.closest(".experience-fragment") ||
                  !element?.closest(".target")) &&
                !element?.closest(".section-background-container")
              ) {
                // For normal callouts
                if (
                  this.componentHost
                    ?.closest(".callout-container")
                    ?.previousElementSibling?.previousElementSibling?.classList?.contains(
                      "section-background-container",
                    )
                ) {
                  this.dataEvent.event_data.bloc_name = this.componentHost
                    ?.closest(".callout-container")
                    ?.previousElementSibling?.querySelector(
                      '[class*="expressive-heading-"]',
                    )
                    ?.innerText?.trim()
                    ?.toLowerCase();
                  this.dataEvent.event_data.bloc_interaction = calloutTitle;
                } else {
                  this.dataEvent.event_data.bloc_name = "ibis offers";
                  this.dataEvent.event_data.bloc_interaction = calloutTitle;
                }
              } else if (element?.closest(".section-background-container")) {
                // For section callout
                this.dataEvent.event_data.bloc_name = "social events at ibis";
                this.dataEvent.event_data.bloc_interaction = calloutTitle;
              }
            } else if (
              this.dataLayerJson?.pageName === "ibis::magazine::category"
            ) {
              // Magazine Category template
              this.dataEvent.event_data.bloc_name = this.pageHeadingTitle;
              this.dataEvent.event_data.bloc_interaction = calloutTitle;
            } else if (this.dataLayerJson?.pageName === "ibis::offers::hub") {
              // Offers Hub template
              this.dataEvent.event_data.bloc_name = event?.target
                ?.closest(".ace-callout-component")
                ?.getAttribute("data-filters");
              this.dataEvent.event_data.bloc_interaction = calloutTitle;
            } else if (
              this.dataLayerJson?.pageName === "ibis::offers::details"
            ) {
              // Offers Details template
              if (
                event?.target
                  ?.closest(".ace-callout-component")
                  ?.getAttribute("data-product-id")
              ) {
                this.dataEvent.event_data.bloc_name = this.pageHeadingTitle;
                this.dataEvent.event_data.bloc_interaction = calloutTitle;
              } else {
                this.dataEvent.event_data.bloc_name = this.pageHeadingTitle;
                this.dataEvent.event_data.bloc_interaction = "other offer";
                this.dataEvent.event_data.bloc_type = calloutTitle;
              }
            } else if (this.dataLayerJson?.pageName === "ibis::services") {
              // Service template
              this.dataEvent.event_data.bloc_name = "other brands";
              this.dataEvent.event_data.bloc_interaction = `go to ${calloutTitle}`;
            } else if (
              this.dataLayerJson?.pageName?.split("::")[1] === "go get it"
            ) {
              // GO get it template
              delete this.dataEvent.event_data.bloc_type;
              if (this.componentHost?.querySelector(".section-left")) {
                this.dataEvent.event_data.bloc_name =
                  "some & influencer activations";
                this.dataEvent.event_data.bloc_interaction =
                  "ibis social media";
                this.dataEvent.event_data.bloc_type = `${calloutTitle} | ${index + 1}`;
              } else if (
                event?.target
                  ?.closest(".ace-callout-component")
                  ?.getAttribute("data-product-id")
              ) {
                this.dataEvent.event_data.bloc_name = "hotel push";
                this.dataEvent.event_data.bloc_interaction = "see hotel page";
                this.dataEvent.event_data.bloc_type = `${event?.target?.closest(".ace-callout-component")?.getAttribute("data-product-id")} | ${index + 1}`;
              } else if (element?.closest(".section-background-container")) {
                // For section callout
                this.dataEvent.event_data.bloc_name = "social events at ibis";
                this.dataEvent.event_data.bloc_interaction = calloutTitle;
                if (
                  element
                    ?.closest(".section-background-container")
                    ?.previousElementSibling?.classList?.contains(
                      "callout-container",
                    )
                ) {
                  this.dataEvent.event_data.bloc_name = calloutTitle;
                  this.dataEvent.event_data.bloc_interaction = "read article";
                }
              } else {
                this.dataEvent.event_data.bloc_name = "offers";
                this.dataEvent.event_data.bloc_interaction = "see offer page";
                this.dataEvent.event_data.bloc_type = `${calloutTitle} | ${index + 1}`;
              }
            } else {
              this.dataEvent.event_data.bloc_name = calloutTitle;
            }
            TagManager.trackEvent(this.dataEvent);
          });
      });
    }
  }

  /**
   * push ga events for callout container ctas in tag manager depending on the page
   */
  calloutCtaTagManagerHandle() {
    this.calloutContainerCTA?.addEventListener(
      CoreJS.DomEventConstants.CLICK,
      () => {
        if (this.dataLayerJson?.pageName === "ibis::brand::homepage") {
          // Homepage
          if (this.componentHost?.closest(".section-background-container")) {
            // For section callout
            this.dataEvent.event_data.bloc_name = "what s on";
            this.dataEvent.event_data.bloc_interaction = "see more articles";
          } else if (
            this.componentHost?.closest(".experience-fragment") ||
            // eslint-disable-next-line no-undef
            element?.closest(".target")
          ) {
            // For experience fragement
            if (
              this.componentHost
                ?.closest(".experience-fragment")
                ?.previousElementSibling?.previousElementSibling?.classList?.contains(
                  "booking-engine-container",
                ) ||
              this.componentHost
                ?.closest(".target")
                ?.previousElementSibling?.previousElementSibling?.classList?.contains(
                  "booking-engine-container",
                )
            ) {
              this.dataEvent.event_data.bloc_name = "ibis offers";
              this.dataEvent.event_data.bloc_interaction = "see more products";
            } else {
              this.dataEvent.event_data.bloc_name = "destinations";
              this.dataEvent.event_data.bloc_interaction = "see more products";
            }
          }
        } else if (this.dataLayerJson?.pageName?.split("::")[1] === "brands") {
          if (this.componentHost?.closest(".section-background-container")) {
            // For section callout
            this.dataEvent.event_data.bloc_name = `${this.dataLayerJson?.pageName?.split("::")[2]} offers`;
            this.dataEvent.event_data.bloc_interaction = "see more products";
          } else if (
            this.componentHost?.closest(".experience-fragment") ||
            // eslint-disable-next-line no-undef
            element?.closest(".target")
          ) {
            // For experience fragement
            this.dataEvent.event_data.bloc_name = `${this.dataLayerJson?.pageName?.split("::")[2]} destinations`;
            this.dataEvent.event_data.bloc_interaction =
              this.calloutContainerCTA
                ?.querySelector("a span")
                ?.innerText?.trim()
                ?.toLowerCase();
          }
        } else if (this.dataLayerJson?.pageName === "ibis::magazine::hub") {
          // Magazine Hub template}
          if (this.componentHost?.closest(".section-background-container")) {
            // For section callout
            this.dataEvent.event_data.bloc_name = this.componentHost
              ?.closest(".callout-container")
              ?.previousElementSibling?.querySelector(
                '[class*="expressive-heading-"]',
              )
              ?.innerText?.trim()
              ?.toLowerCase();
            this.dataEvent.event_data.bloc_interaction = "see all articles";
          } else {
            this.dataEvent.event_data.bloc_name = "ibis offers";
            this.dataEvent.event_data.bloc_interaction = "see all articles";
          }
        } else if (
          this.dataLayerJson?.pageName === "ibis::magazine::category"
        ) {
          // Magazine Category template
          this.dataEvent.event_data.bloc_name = this.pageHeadingTitle;
          this.dataEvent.event_data.bloc_interaction = "see all articles";
        } else if (this.dataLayerJson?.pageName === "ibis::offers::hub") {
          // Offers Hub template
          this.dataEvent.event_data.bloc_name = "show more offers";
        } else if (this.dataLayerJson?.pageName === "ibis::offers::details") {
          // Offers Details template
          this.dataEvent.event_data.bloc_name = this.pageHeadingTitle;
          this.dataEvent.event_data.bloc_interaction = this.calloutContainerCTA
            ?.querySelector(".cmp-button span")
            ?.innerText?.trim()
            ?.toLowerCase();
          delete this.dataEvent.event_data.bloc_type;
        } else if (
          this.dataLayerJson?.pageName === "ibis::destinations::continent"
        ) {
          // destination continent template
          this.dataEvent.event_data.bloc_name = "load more";
        } else if (
          this.dataLayerJson?.pageName?.split("::")[1] === "go get it"
        ) {
          // GO get it template
          delete this.dataEvent.event_data.bloc_type;
          if (this.componentHost?.closest(".section-background-container")) {
            // For section callout
            this.dataEvent.event_data.bloc_name = "social events at ibis";
            this.dataEvent.event_data.bloc_interaction =
              this.calloutContainerCTA
                ?.querySelector(".cmp-button span")
                ?.innerText?.trim()
                ?.toLowerCase();
            if (
              this.componentHost
                ?.closest(".section-background-container")
                ?.previousElementSibling?.classList?.contains(
                  "callout-container",
                )
            ) {
              this.dataEvent.event_data.bloc_name = "other brands";
              this.dataEvent.event_data.bloc_interaction = `go to ${this.calloutContainerCTA?.querySelector(".cmp-button span")?.innerText?.trim()?.toLowerCase()}`;
            }
          } else {
            this.dataEvent.event_data.bloc_name = "social events at ibis";
            this.dataEvent.event_data.bloc_interaction =
              this.calloutContainerCTA
                ?.querySelector(".cmp-button span")
                ?.innerText?.trim()
                ?.toLowerCase();
          }
        } else {
          this.dataEvent.event_data.bloc_name = "social events at ibis";
          this.dataEvent.event_data.bloc_interaction = this.calloutContainerCTA
            ?.querySelector(".cmp-button span")
            ?.innerText?.trim()
            ?.toLowerCase();
        }
        TagManager.trackEvent(this.dataEvent);
      },
    );
  }
}

// Register the callout container component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(
  CalloutContainer.CLASS_NAMESPACE,
  IbisCalloutContainer,
  true,
);
