/* eslint-disable no-unused-expressions */
/**
 * Class representing a Ibis Chip Component instance
 * @author Rishabh
 * @class IbisChip
 * @classdesc Ibis Chip component to inherit the Chip core component
 * @extends Chip
 * @version 1.0
 */
import { Chip, TagManager } from "@accor/ace-ui-core";

export default class IbisChip extends Chip {
  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class
    super(componentHost);
    this.tagManagerHandler();
  }

  tagManagerHandler() {
    const dataLayerValue = document
      .querySelector("[data-cmp-datalayer]")
      ?.getAttribute("data-cmp-datalayer");
    if (dataLayerValue) {
      this.dataLayerJson = JSON.parse(dataLayerValue);
      const btns = this.componentHost?.querySelectorAll(
        ".ace-chip__list .ace-chip--button",
      );
      btns.forEach((btn) =>
        btn?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          const dataEvent = {
            event: "GA4event",
            eventName: "bloc_interact",
            event_data: {
              pagename: this.dataLayerJson?.pageName,
              filter_label: btn
                ?.querySelector(".ace-chip--title")
                ?.innerText?.trim()
                ?.toLowerCase(),
            },
          };
          TagManager.trackEvent(dataEvent);
        }),
      );
    }
  }
}

// Register the QuickAccess component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(Chip.CLASS_NAMESPACE, IbisChip, true);
