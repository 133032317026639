/* eslint-disable no-case-declarations */
import { TagManager, CoreBookingEngine } from "@accor/ace-ui-core";

export default class IbisCoreBookingEngine extends CoreBookingEngine {
  /** @inheritDoc */
  constructor(componentHost, componentName) {
    super(componentHost, componentName);
    this.componentHost
      .querySelector(".ace-core-booking-engine__options-trigger button")
      ?.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
        if (event?.target?.getAttribute("aria-expanded") == "true") {
          this.tagManagerHandler("open_advanced_booking_form");
        }
      });
  }

  /**
   * Form submit handler
   * @param {String} eventName
   */
  tagManagerHandler(eventName) {
    const dataLayerValue = document
      .querySelector("[data-cmp-datalayer]")
      ?.getAttribute("data-cmp-datalayer");
    if (dataLayerValue) {
      this.dataLayerJson = JSON.parse(dataLayerValue);
      const $pagename = this.dataLayerJson.pageName;
      let $eventLabel = "";
      let $eventData = {};
      window.dataLayer.push({ event_data: null });
      switch (eventName) {
        case "open_advanced_booking_form":
          this.gaEventName = "";
          $eventLabel = "booking_form_interact";
          $eventData = {
            pagename: $pagename,
            form_action: "openadvanced",
            bloc_name: "special rates and accessibility",
          };
          break;
        case "submit_booking_form":
          this.gaEventName = "";
          const toatlRooms = parseInt(
            this.componentHost.querySelector('[data-input="room"]')?.value,
          );
          $eventLabel = "booking_form_submit";
          const arrivalDates = this.componentHost
            .querySelector(".ace-core-booking-engine__check-in .hydrated")
            ?.getAttribute("value");
          const arrivalDatesFormat = new Date(arrivalDates);
          const eventDate = new Date();
          let leadTimeDate = "";
          if (arrivalDates && arrivalDates != "") {
            const diiferenceTime = Math.abs(arrivalDatesFormat - eventDate);
            leadTimeDate = Math.ceil(diiferenceTime / (1000 * 60 * 60 * 24));
          }
          $eventData = {
            pagename: $pagename,
            accomodation_type: this.componentHost
              .querySelector(
                '.ahAutocomplete__input__hidden input[name="search.geoZone.geoZoneType"]',
              )
              ?.getAttribute("value"),
            arrival_dates: arrivalDates,
            departure_date: this.componentHost
              .querySelector(".ace-core-booking-engine__check-out .hydrated")
              ?.getAttribute("value"),
            number_of_rooms: toatlRooms,
            adults_nb: this.totalAdults,
            children_nb: this.totalChildren,
            lead_time: leadTimeDate,
            night_nb: this.componentHost
              .querySelector(
                '.core-booking-engine__date-search input[name="search.nightNb"]',
              )
              ?.getAttribute("value"),
          };
          break;
        case "error_booking_form":
          this.gaEventName = "error";
          this.errorFieldForOptions();
          $eventLabel = "booking_form_interact";
          const countArray = [];
          const eventFormErrorBlank = [];
          let eventFormErrorInvalid = [];
          this.event_form_errors_type?.forEach((item, index) => {
            if (item == "blank") {
              countArray.push(index);
            }
          });
          this.event_form_errors?.forEach((object, index) => {
            if (countArray?.length > 0) {
              countArray.forEach((item) => {
                if (item == index) {
                  eventFormErrorBlank.push(object);
                } else {
                  if (!eventFormErrorInvalid.includes(object)) {
                    eventFormErrorInvalid.push(object);
                  }
                }
              });
            } else {
              eventFormErrorInvalid.push(object);
            }
          });
          eventFormErrorInvalid = eventFormErrorInvalid.filter((el) => {
            return !eventFormErrorBlank.includes(el);
          });

          if (eventFormErrorBlank?.length > 0) {
            const eventFieldsBlank = `${eventFormErrorBlank?.toString()?.replaceAll(",", "|")}`;
            const errorTypeBlank = "blank";
            const datasEventBlank = {
              event: "GA4event",
              eventName: $eventLabel,
              event_data: {
                pagename: $pagename,
                form_action: "validate search",
                bloc_name: "search",
                error_type: errorTypeBlank,
                error_field: eventFieldsBlank,
              },
            };
            // eslint-disable-next-line no-undef
            TagManager.trackEvent(datasEventBlank);
          }
          if (eventFormErrorInvalid?.length > 0) {
            const eventFieldsInvalid = `${eventFormErrorInvalid?.toString()?.replaceAll(",", "|")}`;
            const errorTypeInvalid = "invalid value";
            const datasEventInvalid = {
              event: "GA4event",
              eventName: $eventLabel,
              event_data: {
                pagename: $pagename,
                form_action: "validate search",
                bloc_name: "search",
                error_type: errorTypeInvalid,
                error_field: eventFieldsInvalid,
              },
            };
            // eslint-disable-next-line no-undef
            TagManager.trackEvent(datasEventInvalid);
          }
          break;
      }
      const datasEvent = {
        event: "GA4event",
        eventName: $eventLabel,
      };
      datasEvent.event_data = $eventData;
      if (datasEvent.eventName != "" && this.gaEventName != "error") {
        // disable event triggering from core
        window.dataLayer.push({ event_data: null });
        // eslint-disable-next-line no-undef
        TagManager.trackEvent(datasEvent);
      }
    }
  }

  errorFieldForOptions() {
    const regEx = /^[0-9a-zA-Z]+$/;
    this.moreOptionsInputs?.forEach((input) => {
      if (input.value.length > 1) {
        switch (input.name) {
          case "identification.fidelityCard.number":
            if (!input.value.match(regEx) || input.value.length != 16) {
              this.errorFieldOptionValuePush("loyalty or subscription number");
            }
            break;
          case "identification.reserverId":
            if (!input.value.match(regEx) || input.value.length > 50) {
              this.errorFieldOptionValuePush("business client with contract");
            }
            break;
          case "identification.reserverContract":
            if (!input.value.match(regEx) || input.value.length != 10) {
              this.errorFieldOptionValuePush("access code");
            }
            break;
          case "identification.preferredCode.code":
            if (!input.value.match(regEx) || input.value.length <= 50) {
              this.errorFieldOptionValuePush("preferential code");
            }
            break;
        }
      }
    });
  }

  // Error fields for options value
  errorFieldOptionValuePush(data) {
    if (this.event_form_errors.includes("other")) {
      this.event_form_errors.pop();
      this.event_form_errors_type.pop();
    }
    if (!this.event_form_errors.includes(data)) {
      this.event_form_errors.push(data);
      this.event_form_errors_type.push("invalid value");
    }
  }
}

// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(
  CoreBookingEngine.CLASS_NAMESPACE,
  IbisCoreBookingEngine,
  true,
);
