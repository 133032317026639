/* eslint-disable no-unused-expressions */
/**
 * Class representing a Ibis Social Media Component instance
 * @author Rishabh
 * @class IbisSocialMedia
 * @classdesc Ibis Social Media component to inherit the Social Media core component
 * @extends SocialMedia
 * @version 1.0
 */
import { SocialMedia, TagManager } from "@accor/ace-ui-core";

export default class IbisSocialMedia extends SocialMedia {
  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class
    super(componentHost);
    const dataLayerValue = document
      .querySelector("[data-cmp-datalayer]")
      ?.getAttribute("data-cmp-datalayer");
    if (dataLayerValue) {
      this.dataLayerJson = JSON.parse(dataLayerValue);
    }
    this.dataEvent = {
      event: "GA4event",
      eventName: "bloc_interact",
      event_data: {
        pagename: this.dataLayerJson?.pageName,
      },
    };
    this.socialMediaFollowLink = this.componentHost.querySelector(
      ".cmp-socialMedia_cta a",
    );
    this.TagManagerHandler();
  }

  /**
   * Push data in tagmanager
   */
  TagManagerHandler() {
    if (this.dataLayerJson) {
      this.socialMediaFollowLink?.addEventListener(
        CoreJS.DomEventConstants.CLICK,
        () => {
          if (this.dataLayerJson?.pageName?.split("::")[1] === "brands") {
            this.dataEvent.event_data.bloc_interaction = "follow us";
            this.dataEvent.event_data.bloc_name = `${this.dataLayerJson?.pageName?.split("::")[2]} instagram`;
          } else if (
            this.dataLayerJson?.pageName === "ibis::magazine::category"
          ) {
            this.dataEvent.event_data.bloc_interaction = document
              .querySelector(
                '.heading .ace-headingpagehero-headline [class*="expressive-heading"]',
              )
              ?.innerText?.trim()
              ?.toLowerCase();
            this.dataEvent.event_data.bloc_name = "follow us on instagram";
            this.dataEvent.event_data.bloc_type = "follow us";
          } else {
            this.dataEvent.event_data.bloc_name = "follow us on instagram";
            this.dataEvent.event_data.bloc_type = "follow us";
          }
          TagManager.trackEvent(this.dataEvent);
        },
      );
    }
  }
}

// Register the Sofitel Social Media component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(
  SocialMedia.CLASS_NAMESPACE,
  IbisSocialMedia,
  true,
);
