/**
 * @author Jonas.Fournel
 * @class SofitelBookingEngine
 * @classdesc JS Class handling Sticky Booking Engine component with HTML tag 'sticky-booking-engine'
 */
export default class StickyBookingEngine extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = "sticky-booking-engine";

  /** @inheritDoc */
  constructor(componentHost, componentName) {
    super(componentHost, componentName);
  }

  /** @inheritDoc */
  initialize() {
    this.mainWrapper = this.componentHost.querySelector(
      ".sticky-booking-engine-wrapper",
    );
    this.focusWrapper = this.componentHost.querySelector(".booking");
    this.stickyOverlay = this.mainWrapper.querySelector(".searching");
    this.datePickerOverlay = this.mainWrapper.querySelector(".booking");
    this.closeIcon = this.mainWrapper.querySelector(".close-icon");
    this.bookingEngineOpenButton = this.datePickerOverlay.querySelector(
      ".ace-core-booking-engine__open--button",
    );
    this.bookingEngineCloseButton = this.datePickerOverlay.querySelector(
      ".ace-core-booking-engine__close--button",
    );
    if (this.mainWrapper.getAttribute("data-booking-open") !== "true") {
      this.mainWrapper.parentElement.classList.add("collapsed");
    }
    const focusable = this.focusWrapper.querySelectorAll("input, button");
    let visibleArray = [];

    focusable.forEach((element) => {
      const elementStyle = window.getComputedStyle(element);
      const isDisplayNone = elementStyle.display === "none";
      const isHidden = elementStyle.visibility === "hidden";

      if (!isDisplayNone && !isHidden) {
        visibleArray = [...visibleArray, element];
      }
    });

    this.stickyOverlay.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      setTimeout(() => {
        focusable[0].focus();
      }, 10);
    });

    this.focusWrapper.addEventListener(
      CoreJS.DomEventConstants.KEY_DOWN,
      (e) => {
        //manage the navigation by tab and shift tab
        if (e.which === 9) {
          const firstFocusable = visibleArray[0];
          const lastFocusable = visibleArray[visibleArray.length - 1];
          const shift = e.shiftKey;

          if (shift) {
            if (document.activeElement === firstFocusable) {
              lastFocusable.focus();
              e.preventDefault();
            }
          } else {
            if (document.activeElement === lastFocusable) {
              firstFocusable.focus();
              e.preventDefault();
            }
          }
        }
        //manage the clocsing of booking engine by pressing escape
        if (e.which === 27) {
          if (
            window.innerWidth >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg
          ) {
            this.closingBookingEngine();
          }
        }
      },
    );

    this.stickyOverlay.addEventListener(
      CoreJS.DomEventConstants.CLICK,
      (event) => {
        event.preventDefault();
        this.mainWrapper.classList.add("opened");
        this.datePickerOverlay.classList.add("active");
        this.bookingEngineOpenButton.dispatchEvent(new Event("click"));
      },
    );
    this.closeIcon.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      this.closingBookingEngine();
    });

    this.bookingEngineCloseButton.addEventListener(
      CoreJS.DomEventConstants.CLICK,
      () => {
        this.datePickerOverlay.classList.remove("active");
        this.mainWrapper.classList.remove("opened");
      },
    );

    this.handleBookingEngineStickyHeader();
  }

  handleBookingEngineStickyHeader() {
    const focusWrapperTop =
      this.focusWrapper.getBoundingClientRect().top + window.scrollY;

    this.scrollHandler = this.throttle(() => {
      this.toggleStickyHeader(focusWrapperTop);
    }, 100);

    this.toggleStickyHeader(focusWrapperTop);

    document.addEventListener("scroll", this.scrollHandler);
  }

  toggleStickyHeader(focusWrapperTop) {
    const currentScroll = window.scrollY || window.pageYOffset;
    const parentElement = this.mainWrapper.parentElement;
    const shouldBeSticky = currentScroll >= focusWrapperTop;
    const stateHasChanged =
      shouldBeSticky !== parentElement.classList.contains("visible");

    if (stateHasChanged) {
      this.updateVisibility(parentElement, shouldBeSticky, currentScroll);
    }
  }

  updateVisibility(element, shouldBeSticky, currentScroll) {
    document.removeEventListener("scroll", this.scrollHandler);

    element.classList.toggle("visible", shouldBeSticky);

    // Adjust the scroll position to currentScroll as the visible class toggling causes a scroll jump due to the fixed position which breaks the page flow
    window.scrollTo(0, currentScroll);

    // Wait for the next repaint before re-adding the scroll event listener to avoid flickering/infinite scroll loop
    requestAnimationFrame(() => {
      document.addEventListener("scroll", this.scrollHandler);
    });
  }
  // @todo: either move this function to a utility file or use a library that provides throttle/debounce functionality
  throttle(func, limit) {
    let lastFunc;
    let lastRan;

    return function (...args) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const context = this;

      if (!lastRan) {
        func.apply(context, args);
        lastRan = Date.now();
      } else {
        clearTimeout(lastFunc);
        lastFunc = setTimeout(
          function () {
            if (Date.now() - lastRan >= limit) {
              func.apply(context, args);
              lastRan = Date.now();
            }
          },
          limit - (Date.now() - lastRan),
        );
      }
    };
  }

  closingBookingEngine() {
    this.stickyOverlay.classList.remove("hidden");
    this.datePickerOverlay.classList.remove("active");
    this.mainWrapper.classList.remove("opened");
    if (this.mainWrapper.getAttribute("data-booking-open") !== "true") {
      this.mainWrapper.parentElement.classList.add("collapsed");
    }
    this.stickyOverlay.focus();

    this.componentHost.setAttribute("aria-modal", false);
    document.documentElement.classList.remove("fixedbyModal");
  }
}

// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(
  StickyBookingEngine.CLASS_NAMESPACE,
  StickyBookingEngine,
);
